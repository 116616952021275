












import { namespace } from "vuex-class";
import { Component, Prop, Vue } from "vue-property-decorator";
import NotFound from "@/views/NotFound.vue";
import ClientsUserManagement from "@/views/usermanagement/ClientsUserManagement.vue";
import EmployeeUserManagement from "@/views/usermanagement/employees/UserManagement.vue";

const user = namespace("user");

@Component({
  components: {
    NotFound,
    "employee-user-management": EmployeeUserManagement,
    ClientsUserManagement,
  },
})
export default class UserManagement extends Vue {
  @user.Getter("isCustomer")
  private isCustomer!: boolean;

  @user.Getter("isEmployee")
  private isEmployee!: boolean;

  @Prop()
  private readonly selectedAccountId: string | undefined;
}
